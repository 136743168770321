import moment from "moment";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { GET_TRAINING_LOG_PAYLOAD_FORMAT, LOG_TYPE_ENUM } from "../../../Constant/constants";
import { useGetTrainingLogQuery } from "../../../Data/services/trainingApi";
import { useLocalization } from "../../../Hooks/useLocalization";

const YearlyCalendar = ({ children }) => {
  const STRING = useLocalization()
  const [year, setYear] = useState(moment());

  const { data, isFetching } = useGetTrainingLogQuery({ type: LOG_TYPE_ENUM.YEAR, date: moment(year).format(GET_TRAINING_LOG_PAYLOAD_FORMAT) }, {
    refetchOnMountOrArgChange: true
  })


  const handlePrevYear = () => {
    setYear(prev => moment(prev).subtract(1, 'years'))
  }

  const handleNextYear = () => {
    setYear(prev => moment(prev).add(1, 'years'))
  }

  return (
    <>
      <div className=" d-flex flex-row flex-wrap justify-content-center  align-items-center justify-content-sm-between">
        <div className="left-side p-sm-0 px-5">
          {moment(year).year()}
        </div>
        <div className="right-side d-flex flex-row">
          <div className="align-items-center d-flex flex-row justify-content-between text-center mx-2">
            <span className="left-arrow cursor-pointer" onClick={handlePrevYear}>
              <MdKeyboardArrowLeft />
            </span>
            <span className="center-text">
              {moment(year).year()}
            </span>
            <span className="right-arrow cursor-pointer" onClick={handleNextYear}>
              <MdKeyboardArrowRight />
            </span>
          </div>
          {children}
        </div>
      </div>
      <div className="mt-3">
        <div className="yearly-view focus-in-expand">
          {STRING.MonthNames.map((item, index) => (
            <div className="month">
              <span>{item}</span>
              <br></br>
              {isFetching ? <Spinner size="sm" className="d-flex my-1 mx-auto" /> : <span>{`${parseFloat(data?.data[index]?.time_period).toFixed(2) || 0} ${STRING.Mins}`}</span>}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default YearlyCalendar;
