import React, { useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';

// global scss for globally styles and fonts
// import './_fonts.scss';
// import './_global.scss';


import PublicRoute from "./Components/Routes/PublicRoute";
import { WEB_ROUTES } from "./Constant/constants";
import { baseRoute, removeSplashScreen } from "./Helper";

import ProtectedLayout from "./Components/Layouts/ProtectedLayout";
import ProtectedRoute from "./Components/Routes/ProtectedRoute";

import { uniqueId } from "lodash";
// import { STRING } from "./Constant/StringConstant";

import { useDispatch, useSelector } from "react-redux";
import { logout, setUser } from "./Data/Features/authSlice";
import { getToken } from "./Data/services/localStorageService";
import { userAuthApi } from "./Data/services/userAuthApi";
import Accolades from "./Pages/Accolades";
import Account from "./Pages/Account";
import Community from "./Pages/Community";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Dashboard from "./Pages/Dashboard";
import Feedback from "./Pages/Feedback/Feedback";
import Messages from "./Pages/Messages";
import OtherProfile from "./Pages/OtherProfile";
import Payment from "./Pages/Payment/Payment";
import Privacy from "./Pages/Privacy";
import Profile from "./Pages/Profile/";
import Progress from "./Pages/Progress";
import Purchase from "./Pages/Purchase";
import Setting from "./Pages/Settings/Setting";
import Subscription from "./Pages/Subscription/Subscription";
import { useLocalization } from "./Hooks/useLocalization";


function App() {
  const code = useSelector(state => state.language.code)
  const STRING = useLocalization()
  const dispatch = useDispatch()

  const [getProfile] = userAuthApi.endpoints.getProfile.useLazyQuery()

  useEffect(() => {
    const token = getToken();
    if (token) {
      getUserProfile(token);
    }
    else {
      dispatch(logout());
      removeSplashScreen()
    }
  }, [])


  async function getUserProfile(api_token) {
    try {
      const response = await getProfile(api_token).unwrap();
      console.log(response?.data);
      dispatch(setUser(response?.data));
    }
    catch (err) {
      console.log(err);

      // removeToken()
      // dispatch(logout());
    }
    finally {
      removeSplashScreen()
    }
  }

  // todo: menu
  const ROUTES = useMemo(() => {
    return [
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.ACCOUNT)} element={
        <PublicRoute >
          <Account />
        </PublicRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.DASHBOARD)} element={
        <ProtectedRoute>
          <ProtectedLayout
            title={STRING.Dashboard}
            isNavBar={true}
            selectedRoute={0}>
            <Dashboard />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.COMMUNITY)} element={
        <ProtectedRoute>
          <ProtectedLayout
            title={STRING.Community}
            selectedRoute={1}
            isNavBar={true}
            isSettingButton={false}
            isSearchBar={false} >
            <Community />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,

      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.MENU)} element={
        <ProtectedRoute>
          <ProtectedLayout
            title={STRING.Progress}
            selectedRoute={2}
            selectedSubItems={0}
            isNavBar={true}
            isSettingButton={false}
            isSearchBar={false}
          >
            <Progress />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,

      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.PROGRESS)} element={
        <ProtectedRoute>
          <ProtectedLayout
            title={STRING.Progress}
            selectedRoute={2}
            selectedSubItems={0}
            isNavBar={true}
            isSettingButton={false}
            isSearchBar={false}
          >
            <Progress />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,

      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.ACCOLADES)} element={
        <ProtectedRoute>
          <ProtectedLayout
            title={STRING.Accolades}
            selectedRoute={2}
            selectedSubItems={1}
            isNavBar={true}
            isSettingButton={false}
            isSearchBar={false}
          >
            <Accolades />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,

      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.FEEDBACK)} element={
        <ProtectedRoute>
          <ProtectedLayout
            title={STRING.Feedback}
            selectedRoute={2}
            selectedSubItems={2}
            isNavBar={true}
            isSettingButton={false}
            isSearchBar={false}
          >
            <Feedback />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,

      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.CONTACTUS)} element={
        <ProtectedRoute>
          <ProtectedLayout
            title={STRING.ContactUs}
            selectedRoute={2}
            selectedSubItems={3}
            isNavBar={true}
            isSettingButton={false}
            isSearchBar={false}
          >
            <ContactUs />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,

      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.TEAM)} element={
        <ProtectedRoute >
          <ProtectedLayout
            title={STRING.Team}
            selectedRoute={3}
            isNavBar={true}
            isSettingButton={false}
            isSearchBar={false}
          >
            {/* <Team /> */}
            <Messages />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,

      // <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.CUSTOMER_SUPPORT)} element={
      //   <ProtectedRoute>
      //     <ProtectedLayout selectedRoute={4} isNavBar={true} isSettingButton={false} isSearchBar={false}>
      //       <Messages />
      //     </ProtectedLayout>
      //   </ProtectedRoute>
      // } />,

      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.SETTING)
      } element={
        <ProtectedRoute >
          <ProtectedLayout
            title={STRING.Settings}
            selectedRoute={4}
            isNavBar={true}
            isSettingButton={false}
            isSearchBar={false}
          >
            <Setting />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.PAYMENT)} element={
        <ProtectedRoute >
          <ProtectedLayout
            title={STRING.Payment}
            selectedRoute={5}
            isNavBar={true}
            isSettingButton={false}
            isSearchBar={false}
          >
            <Payment />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.PRIVACY)} element={
        <ProtectedRoute >
          <ProtectedLayout
            title={STRING.PrivacyPolicy}
            selectedRoute={6}
            isNavBar={true}
            isSettingButton={false}
            isSearchBar={false}
          >
            <Privacy />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.PROFILE)} element={
        <ProtectedRoute >
          <ProtectedLayout
            title={STRING.Profile}
            selectedRoute={7}
            isNavBar={true}
            isSettingButton={false}
            isSearchBar={false}
          >
            <Profile />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.SUBSCRIPTION)} element={
        <ProtectedRoute >
          <ProtectedLayout
            title={STRING.Subscription}
            selectedRoute={8}
            isNavBar={true}
            isSettingButton={false}
            isSearchBar={false}
          >
            <Subscription />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.OTHER_PROFILE + ':id')} element={
        <ProtectedRoute >
          <ProtectedLayout
            title={STRING.OtherProfile}
            selectedRoute={9}
            isNavBar={true}
            isSettingButton={false}
            isSearchBar={false}
          >
            <OtherProfile />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.PURCHASE)} element={
        <ProtectedRoute >
          <ProtectedLayout
            title={STRING.Purchase}
            isNavBar={true}
            isSettingButton={false}
            isSearchBar={false}
          >
            <Purchase />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
    ]

  }, [code])

  return (
    <Router>
      <Routes>
        {ROUTES.map((item) => item)}
        <Route path={baseRoute('*')} element={<Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} />} />
      </Routes>

    </Router>
  );
}

export default App;
