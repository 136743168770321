import React, { useMemo, useState } from 'react'
import { ErrorToast } from '../../../Hooks/useToast'
import DownloadTag from './DownloadTag'
import EditSign from './EditSign'
import PremiumSign from './PremiumSign'
import axios from "axios"
import { API_URL } from '../../../config/config'
import { END_POINTS } from '../../../Constant/EndPointsContants'
import { useSelector } from 'react-redux'
import { saveAs } from 'file-saver'
import { Spinner } from 'react-bootstrap'

const TemplateCard = ({ data = {}, openFreeTemplate, openPremiumTemplate }) => {
    const user = useSelector(state => state.authInfo.user)
    const [isDownloading, setIsDownloading] = useState(false)
    const isPremium = useMemo(() => !!data.is_paid, [])
    const handleViewPage = () => {

        if (isPremium) {
            // setPage(ROUTES.TEMPLATE_VIEW_PREMIUM);
            openPremiumTemplate(data);
        } else {
            // setPage(ROUTES.TEMPLATE_VIEW);
            openFreeTemplate(data);
        }


    }

    const handleDownloadTemplate = async (e) => {
        e.stopPropagation()
        if (isDownloading) return;
        try {
            setIsDownloading(true)
            const res = await axios.get(API_URL + END_POINTS.Dashboard.DOWNLOAD_TEMPLATE(data.slug), {
                headers: {
                    "Authorization": "Bearer " + user.api_token,
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'arraybuffer',

            })
            const blob = new Blob([res.data], { type: 'application/pdf' })
            saveAs(blob, "template.pdf")
            setIsDownloading(false)
        } catch (e) {
            if (e?.data?.code === 400) {
                ErrorToast(e.data?.message)
            }
            setIsDownloading(false)
        }
    }

    return (
        <div className='templateContainer' onClick={handleViewPage} role="button">


            <div className='textContainer'>
                <p className='title lc-1 m-0 fs-4'> {data.name} </p>
                {/* <p className='description lc-2 m-0 fs-6'> {description} </p> */}
            </div>

            {
                isPremium ?
                    <PremiumSign />
                    :
                    isDownloading ?
                        <DownloadTag text={<Spinner size="sm" />} isEdit={false} />
                        : <>
                            <DownloadTag text={'Download Template'} onClick={handleDownloadTemplate} />
                            {/* <EditSign /> */}
                        </>
            }
        </div>
    )
}

export default TemplateCard
