import React, { useEffect, useMemo, useState } from "react";
import { InputGroup, Spinner } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import { INPUT_TYPES, INVALID_FLOAT_INPUT_TYPE, INVALID_NUMBER_INPUT_TYPE } from "../../Constant/InputTypesAndPatternsConstant";
import { Button, Checkbox, Input } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
// import cssStyles from './styles/notification.module.css';
import dayjs from "dayjs";
import _ from "lodash";
import { MdModeEditOutline } from "react-icons/md";
import { TOAST_MESSAGE } from "../../Constant/Message";
import { useGetTemplateDetailsQuery, useUpdateTemplateMutation } from "../../Data/services/dashboardApi";
import { useLocalization } from "../../Hooks/useLocalization";
import Toast, { ErrorToast, SuccessToast } from "../../Hooks/useToast";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import { TimeFieldDefaultValueComponent } from "../CustomComponents/TimeFieldComponent";


const EditWorkoutTemplateModal = ({ template_slug = '', data, handleModalClose }) => {
    const STRING = useLocalization()
    const [updateTemplate] = useUpdateTemplateMutation();

    const { data: details, isLoading } = useGetTemplateDetailsQuery(template_slug);
    const templateData = useMemo(() => {
        if (_.isEmpty(details) && _.isEmpty(data)) return {};
        return (
            {
                ...(data ? data : {}),
                ...(details?.data ? details.data : {})
            }
        )

    }, [details])

    const [modalClass, setModalClass] = useState(
        "addWorkoutTemplateModalContainer scale-in-hor-center"
    );

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [selectedValue, setSelectedValue] = useState(!!templateData.is_paid ? "paid" : "free");

    // const [exercises, setExercises] = useState([{ name: "", templateData: "" }]);

    //   const [selectedIntensity, setSelectedIntensity] = useState("");
    //   const handleIntensityChange = (value) => setSelectedIntensity(value);
    const optionList = ["Easy Effort", "Moderate Effort", "Intense Effort"];
    //   const handleEditProfile = (value) => setIsEditProfile(value);
    //   const [isEditProfile, setIsEditProfile] = useState(false);

    const [templateName, setTemplateName] = useState(templateData.name || "");
    const [duration, setDuration] = useState(templateData.duration || undefined);
    const [calories, setCalories] = useState(templateData.calories || undefined);
    const [price, setPrice] = useState(!!templateData.is_paid ? templateData.price : undefined);
    //   const [editTemplateNameMode, setEditTemplateNameMode] = useState(false);
    // const [sectionName, setSectionName] = useState("Section Name");

    const [sections, setSections] = useState([
        {
            title: "",
            exercises: [
                {
                    name: "",
                    intensity: "",
                    standard_time: null,
                    goal_time: null,
                    notes: "",
                },
            ],
        },
    ]);


    useEffect(() => {
        if (!_.isEmpty(details?.data)) {
            setSections(details?.data.section.map(item => {
                return {
                    slug: item.slug,
                    title: item.title,
                    exercises: item.exercise.map(exercise => {
                        return {
                            ...exercise,
                            standard_time: dayjs(exercise.standard_time, 'LT'),
                            goal_time: dayjs(exercise.goal_time, 'LT')
                        }
                    })
                }
            }))
        }
    }, [details])


    const addNewSection = () => {
        const newSections = _.cloneDeep(sections);
        newSections.push({
            title: "",
            exercises: [
                {
                    name: "",
                    intensity: "",
                    standard_time: null,
                    goal_time: null,
                    notes: "",
                },
            ],
        });
        setSections(newSections);
    };

    const addNewExercise = (sectionIndex) => {
        const newSections = _.cloneDeep(sections);
        newSections[sectionIndex].exercises.push({
            name: "",
            intensity: "",
            standard_time: null,
            goal_time: null,
            notes: "",
        });
        setSections(newSections);
    };

    const handleInputChange = (e, sectionIndex, exerciseIndex, field) => {
        const newSections = _.cloneDeep(sections);

        if (field === "intensity") {
            newSections[sectionIndex].exercises[exerciseIndex][field] = e;
        } else if (field === "standard_time" || field === "goal_time") {

            const time = new Date(e?.$y, e?.$M, e?.$D, e?.$H, e?.$m, e?.$s);

            newSections[sectionIndex].exercises[exerciseIndex][field] = e;
        } else {
            newSections[sectionIndex].exercises[exerciseIndex][field] =
                e.target.value;
        }

        setSections(newSections);
    };

    const handleSectionTitleChange = (e, sectionIndex) => {
        const newSections = _.cloneDeep(sections);
        newSections[sectionIndex].title = e.target.value;
        setSections(newSections);
    };

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
    };

    const handleModalCloseWithAnimation = () => {
        setModalClass("addWorkoutTemplateModalContainer scale-out-horizontal");
        setTimeout(() => {
            handleModalClose();
        }, 500);
    };

    const handleSubmit = async () => {
        if (isSubmitted || isLoading) return;

        if (_.isEmpty(templateName)) return ErrorToast('Template name should not be empty.');
        if (!duration) return ErrorToast('Template duration should not be empty.');
        if (!parseInt(duration) || (parseInt(duration) <= 0)) return ErrorToast('Template duration should be greater than 0.');
        if (!calories) return ErrorToast('Template calories should not be empty.');
        if (!parseInt(calories) || (parseInt(calories) <= 0)) return ErrorToast('Template calories should be greater than 0.');
        if ((selectedValue === 'paid')) {
            if (!price) return ErrorToast('Template price should not be empty for paid template.');

            if (!parseFloat(price) || (parseFloat(price) <= 0)) return ErrorToast('Template price should be greater than 0.');

        }

        for (let i = 0; i < sections.length; i++) {
            const section = sections[i];
            if (_.isEmpty(section.title)) return ErrorToast('Section title should not be empty.');

            const exercises = section.exercises;
            for (let j = 0; j < exercises.length; j++) {
                const exercise = exercises[j];

                if (_.isEmpty(exercise.name)) return ErrorToast('Exercise name should not be empty.');
                if (_.isEmpty(exercise.intensity)) return ErrorToast('Please select exercise intensity.');
                if (_.isEmpty(exercise.standard_time)) return ErrorToast('Please define exercise standard time.');
                if (!dayjs(exercise.standard_time).isValid()) return ErrorToast('Invalid exercise standard time.');
                if (_.isEmpty(exercise.goal_time)) return ErrorToast('Please define exercise goal time.');
                if (!dayjs(exercise.goal_time).isValid()) return ErrorToast('Invalid exercise goal time.');
            }
        }

        try {

            setIsSubmitted(true)

            const payload = {};
            payload.name = templateName;
            payload.duration = parseInt(duration);
            payload.calories = parseInt(calories);
            payload.is_paid = (selectedValue === 'paid')
            payload.price = (selectedValue === 'paid') ? parseFloat(price) : 0
            payload.section = sections.map(section => {
                return {
                    slug: section.slug,
                    title: section.title,
                    exercise: section.exercises.map(exercise => {
                        return {
                            slug: exercise.slug,
                            name: exercise.name,
                            intensity: exercise.intensity,
                            standard_time: dayjs(exercise.standard_time).format('LT'),
                            goal_time: dayjs(exercise.goal_time).format('LT'),
                            notes: exercise.notes

                        }
                    })
                }
            })

            const response = await updateTemplate({ slug: template_slug, data: payload });

            if (response.error)
                if (response.error.status === 400) {
                    const message = await response.error.data.message;
                    Toast(`${message}`, "error");
                }
                else {
                    throw new Error('SERVER_ERROR');
                }

            if (!response.error && response.data.code === 200) {
                SuccessToast(TOAST_MESSAGE.SUCCESS.TEMPLATE_CREATED);
                handleModalCloseWithAnimation()
            }
        } catch (error) {
            if (error?.data && error?.data?.message) {
                Toast(error?.data?.message, "error");
            } else {
                Toast(TOAST_MESSAGE.ERROR.TEMPLATE_CREATION, "error");
            }
        }
        finally {
            setIsSubmitted(false)
        }

    };


    return (
        <div>
            {
                <ModalContainer handleClose={handleModalCloseWithAnimation}>
                    <div className={modalClass}>
                        <div className="d-flex align-items-center w-100 justify-content-between">
                            {
                                // <p className='fs-4 fw-medium m-0'>
                                //     {STRING.TemplateName}
                                // </p>
                            }
                            {
                                // MARK: Tamplate Name Editable
                            }
                            <div className="templateName">
                                <input
                                    type="text"
                                    value={templateName}
                                    onChange={(e) => {
                                        setTemplateName(e.target.value);
                                    }}
                                    placeholder={STRING.TemplateName}
                                    className="templateNameField"
                                />
                                <MdModeEditOutline color="#F68B28" />
                            </div>

                            <div className={"closeBtn"}>
                                <FaCircleXmark
                                    fontSize={40}
                                    color={"#F68B28"}
                                    onClick={handleModalCloseWithAnimation}
                                />
                            </div>
                        </div>
                        {
                            // MARK: Check Boxes
                        }
                        <div className="w-100 d-flex flex-row align-items-center justify-content-between mt-2">
                            <Input
                                classes="mx-3"
                                placeholder={STRING.Duration}
                                type={INPUT_TYPES.NUMBER}
                                value={duration}
                                onChange={e => setDuration(e.target.value)}
                                onKeyDown={(e) => (INVALID_NUMBER_INPUT_TYPE.includes(e.key) && e.preventDefault())}
                            />
                            <Input
                                classes="mx-3"
                                placeholder={STRING.Calories}
                                type={INPUT_TYPES.NUMBER}
                                value={calories}
                                onChange={e => setCalories(e.target.value)}
                                onKeyDown={(e) => (INVALID_NUMBER_INPUT_TYPE.includes(e.key) && e.preventDefault())}
                            />
                        </div>
                        {
                            // MARK: Check Boxes
                        }
                        <div
                            className={`w-100 m-1 d-flex flex-row justify-content-around checkBoxes scale-in-center-slow`}
                        >
                            <Checkbox
                                classes="m-2 "
                                label={STRING.Free}
                                value="free"
                                checked={selectedValue === "free"}
                                onChange={handleCheckboxChange}
                            />

                            <Checkbox
                                classes="m-2 "
                                label={STRING.Paid}
                                value="paid"
                                checked={selectedValue === "paid"}
                                onChange={handleCheckboxChange}
                            />
                        </div>
                        {
                            (selectedValue !== 'paid') ? "" :
                                <div className=" d-flex flex-row align-items-center justify-content-between mb-2">
                                    <Input
                                        placeholder={STRING.TemplatePrice}
                                        type={INPUT_TYPES.NUMBER}
                                        value={price}
                                        onChange={e => setPrice(e.target.value)}
                                        onKeyDown={(e) => (INVALID_FLOAT_INPUT_TYPE.includes(e.key) && e.preventDefault())}
                                    />
                                </div>
                        }

                        {
                            //MARK: SECTION
                        }

                        {isLoading ? <div className="my-3"><Spinner /></div> :
                            <>
                                {sections.map((section, sectionIndex) => (
                                    <div className="sectionArea" key={sectionIndex + 1}>
                                        <div className="fs-7 fw-bold mb-2 w-100">
                                            <input
                                                type="text"
                                                value={section.title}
                                                placeholder={STRING.SectionName}
                                                onChange={(e) => handleSectionTitleChange(e, sectionIndex)}
                                                className="sectionNameField"
                                            />{" "}
                                            <MdModeEditOutline color="#F68B28" />
                                        </div>

                                        {section.exercises.map((exercise, exerciseIndex) => (
                                            <>
                                                {exerciseIndex > 0 ? <hr></hr> : ""}

                                                <InputGroup>
                                                    <Input
                                                        classes="bg-darkJungleGreen"
                                                        placeholder={STRING.ExerciseName}
                                                        type={INPUT_TYPES.TEXT}
                                                        value={exercise.name}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                sectionIndex,
                                                                exerciseIndex,
                                                                "name"
                                                            )
                                                        }
                                                    // onBlur={onBlur}
                                                    />
                                                    {
                                                        // <div className="input-before-icon" >
                                                        //     <CiUser />
                                                        // </div>
                                                    }
                                                </InputGroup>

                                                <SelectFieldComponent
                                                    //   firstOption={v}
                                                    optionsList={optionList}
                                                    //   onSelectChange={handleIntensityChange}
                                                    // disabled={isEditProfile ? false : true}
                                                    firstOption={
                                                        exercise.intensity !== ""
                                                            ? exercise.intensity
                                                            : STRING.SelectIntensity
                                                    }
                                                    onSelectChange={(e) =>
                                                        handleInputChange(
                                                            e,
                                                            sectionIndex,
                                                            exerciseIndex,
                                                            "intensity"
                                                        )
                                                    }
                                                    className={"select bg-darkJungleGreen"}
                                                />

                                                <TimeFieldDefaultValueComponent
                                                    placeholder={STRING.StandardTime}
                                                    //   onChange={handleChangeStandardTime}
                                                    value={exercise?.standard_time}
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            e,
                                                            sectionIndex,
                                                            exerciseIndex,
                                                            "standard_time"
                                                        )
                                                    }
                                                />

                                                <TimeFieldDefaultValueComponent
                                                    placeholder={STRING.GoalTime}
                                                    //   onChange={() => {}}
                                                    value={exercise?.goal_time}
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            e,
                                                            sectionIndex,
                                                            exerciseIndex,
                                                            "goal_time"
                                                        )
                                                    }
                                                />

                                                <InputGroup>
                                                    <Input
                                                        classes="bg-darkJungleGreen mt-1"
                                                        placeholder={STRING.Note}
                                                        type={INPUT_TYPES.TEXTAREA}
                                                        rows={2}
                                                        as={INPUT_TYPES.TEXTAREA}
                                                        value={exercise.notes}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                sectionIndex,
                                                                exerciseIndex,
                                                                "notes"
                                                            )
                                                        }
                                                    // onBlur={onBlur}
                                                    />
                                                </InputGroup>
                                            </>
                                        ))}

                                        {
                                            // MARK: ADD EXERCISE BUTTON
                                        }

                                        <Button
                                            classes="mt-3 mb-2 rounded-4 scale-in-center-slow fs-6 exerciseBtn "
                                            // text={` ÷ ${STRING.AddExercise}`}
                                            text={
                                                <span className=" d-flex justify-content-center align-items-center">
                                                    <FaPlus color="#F68B28" className="mx-1" />
                                                    <span className="">{` ${STRING.AddExercise}`}</span>
                                                </span>
                                            }
                                            type={INPUT_TYPES.SUBMIT}
                                            onClick={() => addNewExercise(sectionIndex)}
                                        />
                                    </div>
                                ))
                                }

                                < div className="w-100">
                                    <Button
                                        classes="mt-3 mb-2 rounded-4 scale-in-center-slow fs-6 exerciseBtn"
                                        text={
                                            <span className=" d-flex justify-content-center align-items-center">
                                                <FaPlus color="#F68B28" className="mx-1" />
                                                <span className="">{` ${STRING.Section}`}</span>
                                            </span>
                                        }
                                        type={INPUT_TYPES.SUBMIT}
                                        onClick={addNewSection}
                                    />
                                </div>
                                <hr></hr>
                            </>
                        }

                        <div className="w-100">
                            <Button
                                classes="mt-3 mb-2 rounded-4 scale-in-center-slow fs-5"
                                text={
                                    <div className='d-flex align-items-center justify-content-center'>
                                        {STRING.SaveTemplateChanges}{isSubmitted ? <span className='ms-3 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span> : ""}
                                    </div>
                                }
                                type={INPUT_TYPES.SUBMIT}
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                </ModalContainer >
            }
        </div >
    );
};

export default EditWorkoutTemplateModal;
